.flex,
.flex-center {
	display: flex;
}

.flex-same .ant-space-item {
	flex: 1;
}

.flex-one {
	flex: 1;
}

.flex-fill {
	flex: 1 1 auto;
}
.flex-column {
	flex-direction: column;
}
.flex-row {
	flex-direction: row;
}

.flex-shrink-zero {
	flex-shrink: 0;
}

.items-end {
	align-items: flex-end;
}

.justify-end {
	justify-content: flex-end;
}

.justify-space-between {
	justify-content: space-between;
}

.justify-center,
.flex-center {
	justify-content: center;
}

.flex-center {
	align-items: center;
}

.h-100 {
	height: 100%;
}

.w-100 {
	width: 100%;
}

.overflow-scroll-y {
	overflow-x: hidden;
	overflow-y: visible;
}

.scroll-margin > * {
	padding-right: 8px;
}

.visibility-hidden {
	visibility: hidden;
}

.text-capitalize {
	text-transform: capitalize;
}

.min-w-save-btn {
	min-width: 130px;
}
